import React, { Component } from 'react';
import Data from "./Data.json"

class Work extends Component {
  render() {
    return (
      <div class="work">
        {
            Data.work.map((item) => {
                return (
                  <div class="work--item">
                    <div class="work--item-info">
                      <div class="work--item-company"><a href={`http://${item.url}`} title={item.company}>{item.company}</a></div>
                      <div class="work--item-role">{item.role}</div>
                      <div class="work--item-year">{item.year}</div>
                    </div>
                    <div class="work--item-description">
                      {item.description}
                    </div>
                  </div>
                )
            })
        }
      </div>
    );
  }
}

export default Work;