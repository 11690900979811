import React, { Component } from 'react';
import Data from "./Data.json"

class Intro extends Component {
  render() {
    return (
      <div className="intro">
        <p>{Data.intro}</p>
        {/* <a href="mailto:leseeley@gmail.com" title="Email me">Get in touch for a walkthrough of my work →</a> */}
      </div>
    );
  }
}

export default Intro;