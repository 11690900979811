import React, { Component } from 'react';
import './App.css';
import Header from './Header';
import Intro from './Intro';
import Work from './Work';

class App extends Component {
  render() {
    return (
      <div class="wrap">
        <Header />
        <div class="page">
          <Intro />
          <Work />
        </div>
      </div>
    );
  }
}

export default App;